import { Configuration, ContentApi, SnippetAreaApi } from '@/api';
import { MIDDLE_EAST_LOCALES } from '@/constants/locales';
import MIDDLE_EAST_COUNTRIES from '@/constants/countries';
import * as Sentry from '@sentry/vue';

export default function useCms(i18n: any, apiUrl: string, theme: string) {
  // Mapping from layout to webspace
  const webspaceMapping: { [key: string]: string } = {
    europe: 'retail-europe',
    'middle-east': 'retail-arabic',
  };
  const webspace = webspaceMapping[theme];

  const configuration = new Configuration({});
  const getContentApi = (): ContentApi => new ContentApi(configuration, apiUrl);
  const getSnippetApi = (): SnippetAreaApi => new SnippetAreaApi(configuration, apiUrl);
  const currentLocale = (): string => i18n.locale.value;

  const locateLocalePrefix = new RegExp(`^/(${MIDDLE_EAST_LOCALES.join('|')})`, 'i');
  const locateCountryPrefix = new RegExp(`^/(${MIDDLE_EAST_COUNTRIES.join('|')})`, 'i');

  const getHomepage = () => () => $fetch(`${apiUrl}/api/${webspace}/${currentLocale()}?path=`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  const getContentPage = async (path: string): Promise<ContentData> => {
    const contentPath = path
      .replace(locateLocalePrefix, '')
      .replace(locateCountryPrefix, '');
    const api = getContentApi();
    try {
      const { data } = await api.apiWebspaceLocaleContentGet(
        {
          webspace,
          locale: currentLocale(),
          path: contentPath,
        },
        { maxRedirects: 0 },
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      if (error?.response.status === 404) {
        throw createError({ statusCode: 404, statusMessage: 'Page Not Found', fatal: true });
      }
      // to do: Handle redirect (301, 302)
    }

    return null;
  };

  const getSnippet = async (area: string): Promise<any> => {
    const api = getSnippetApi();

    try {
      const { data } = await api.apiWebspaceLocaleSnippetAreasAreaGet(
        {
          webspace,
          locale: currentLocale(),
          area,
        },
        { maxRedirects: 0 },
      );

      return data;
    } catch (e) {
      Sentry.captureException(e);
    }

    return null;
  };

  return {
    getHomepage,
    getContentPage,
    getSnippet,
  };
}
